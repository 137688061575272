import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import BlogCard from "../components/BlogCard";
import Layout from "../components/layout";
import LeadFormWithAPI from "../components/LeadFormWithAPI";
import SEO from "../components/seo";
import Tag from "../components/Tag";
import { blogSliderSettings } from "../constants";
import "../styles/page/landing_page.scss";
import VideoComponent from "../components/VideoComponent";

const SplitSection = ({
  icon,
  title,
  description,
  tags,
  image,
  video,
  direction = "row",
}) => {
  const flexDirection =
    direction === "row-reverse" ? "sm:flex-row-reverse" : "sm:flex-row";
  return (
    <section className="splitSection pt-[30px] sm:pt-0">
      <section
        className={`flex flex-col items-center lg:items-start ${flexDirection} gap-8 lg:gap-20`}
      >
        <section className="pl-[20px] sm:pl-0 pr-[15px] sm:pr-0 flex-1 flex gap-2 md:gap-3 lg:gap-[20px]">
          <section className="hidden sm:block w-[44px] lg:w-[72px] h-[44px] lg:h-[71px] flex-shrink-0">
            {icon}
          </section>
          <section className="text-blue-1100 max-w-[431px]">
            <h3 className="text-[20px] sm:text-[25px] lg:text-[36px] leading-6 sm:leading-[30.26px] lg:leading-11 font-medium tracking-[-0.05em] ">
              {title}
            </h3>
            <p className="text-sm sm:text-[17px] lg:text-xl leading-5 sm:leading-5 lg:leading-7 mt-[10px] sm:mt-[17px] lg:mt-[52px]">
              {description}
            </p>
            <p className="text-sm sm:text-base lg:text-xl leading-5 lg:leading-7 mt-[18px] lg:mt-20 font-bold">
              Learn more about
            </p>
            {!!tags?.length && (
              <section className="flex flex-wrap gap-2 mt-3">
                {tags?.map((x, index) => {
                  const [label, url] = x?.split("::");
                  return <Tag key={index} text={label} url={url} />;
                })}
              </section>
            )}
          </section>
        </section>
        <section className="flex-1 lg:h-[380px] xl:h-[410px]">
          {video ? (
            <VideoComponent loop autoPlay muted>
              <source src={video} type="video/mp4" />
            </VideoComponent>
          ) : (
            <>
              <img
                src={image?.desktop?.url}
                alt={image?.altText || "img"}
                loading="lazy"
                className="hidden w-auto h-auto xs:block lg:h-full"
                width={0}
                height={0}
              />
              <img
                src={image?.mobile?.url}
                alt={image?.altText || "img"}
                loading="lazy"
                className="block w-auto h-auto xs:hidden"
                width={0}
                height={0}
              />
            </>
          )}
        </section>
      </section>
    </section>
  );
};

const EventsNestedLandingPage = ({ location, data }) => {
  const _data =
    data?.allPrismicNestedLandingEventPages?.nodes &&
    data?.allPrismicNestedLandingEventPages?.nodes[0]
      ? data?.allPrismicNestedLandingEventPages?.nodes[0]?.data
      : {};

  const [trusted_brands_list] = _data?.body?.filter(
    (v) => v.slice_type === "trusted_brands_list"
  ) || [[]];
  const [sub_features_list] = _data?.body?.filter(
    (v) => v.slice_type === "sub_features_list"
  ) || [[]];
  const [encord_features] = _data?.body?.filter(
    (v) => v.slice_type === "encord_features"
  ) || [[]];
  const customizable_quality =
    encord_features?.items[encord_features?.items?.length - 1];

  return (
    <Layout location={location}>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <div id="home" />
      <article className="grid grid-cols-12 gap-10 pt-40 pb-10 mx-auto section_container md:pt-40 md:pb-20">
        <section className="self-center col-span-12 mb-8 text-center lg:col-span-6 xl:col-span-7 md:mb-0 lg:text-left">
          <span
            className="heading_gradient text-[36px] sm:text-[30px] md:text-[50px] text-blue-1000 leading-11 sm:leading-12 md:leading-none tracking-[-0.03em] md:tracking-[-0.065em] font-semibold  "
            dangerouslySetInnerHTML={{
              __html: _data?.page_heading?.html,
            }}
          />

          <span
            className="text-[36px] sm:text-[15px] md:text-[30px] text-blue-1000 leading-11 sm:leading-12 md:leading-18 tracking-[-0.03em] md:tracking-[-0.065em]  "
            dangerouslySetInnerHTML={{
              __html: _data?.page_sub_heading?.html,
            }}
          />

          <div
            className="text-base sm:text-xl max-w-none lg:max-w-[650px] ga mt-[26px] md:mt-4 mb-8   text-purple-1200"
            dangerouslySetInnerHTML={{
              __html: _data?.page_description?.html,
            }}
          />

          <section className="leadSignUpForm">
            <LeadFormWithAPI
              btnText="👋 Meet us at CVPR"
              formId="4ed28ad7-5c0e-45be-83d7-13742dde8ebc"
              pageName="Landing Page"
              location={location}
              hideRevenueHero
            />
          </section>
        </section>
        <section className="flex items-center justify-center w-full h-full col-span-12 mx-auto md:mx-0 lg:col-span-6 xl:col-span-5">
          <img
            loading="eager"
            src={_data?.hero_image?.url}
            alt="home page banner"
            className="w-auto h-auto"
            width={0}
            height={0}
          />
        </section>
      </article>

      {/*  sections two*/}
      <article className="linear_background">
        <section className="section_container mx-auto py-9.5 space-y-[16px]">
          <section>
            <p className="text-center text-sm text-blue-1200   tracking-[0.07em]">
              {_data?.trusted_brands_list_label?.text}
            </p>
          </section>
          <section className={`grid grid-cols-2 gap-8 md:grid-cols-8`}>
            {trusted_brands_list?.items?.map((item, index) => {
              return (
                <div
                  key={`brand_${index}`}
                  className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1"
                >
                  <img
                    src={item?.brand_image?.url}
                    alt={item?.brand_image?.alt || "img"}
                    className={`${"object-scale-down h-auto w-auto"} img_grayscale`}
                    loading="lazy"
                    width={0}
                    height={0}
                  />
                </div>
              );
            })}
          </section>
        </section>
      </article>
      {encord_features?.items?.length && (
        <article className="section_container relative mx-auto mt-[40px] md:mt-[91px]">
          <section className="al_assisted_card lg:max-w-[1020px] xl:max-w-[1167px] ml-auto py-7 md:py-9 lg:py-16 px-[6px] items-center lg:items-start">
            <section className="flex-1">
              {/* Need to change styling for video */}
              <div className="static left-0 h-auto xl:w-auto xl:absolute top-8">
                {encord_features?.items[0]?.feature_video_url?.url ? (
                  <VideoComponent
                    loop
                    autoPlay
                    muted
                    className="w-auto xl:max-w-[592px] rounded-lg shadow-2xl"
                  >
                    <source
                      src={encord_features?.items[0]?.feature_video_url?.url}
                      type="video/mp4"
                    />
                  </VideoComponent>
                ) : (
                  <img
                    src={encord_features?.items[0]?.feature_image?.url}
                    alt="ai dashboard"
                    className="w-auto xl:max-w-[592px] rounded-lg shadow-2xl h-auto"
                    loading="lazy"
                    width={0}
                    height={0}
                  />
                )}
              </div>
            </section>
            <section className="flex flex-col flex-1 pl-[20px] sm:pl-0 pr-[15px] sm:pr-0">
              <section className="flex gap-[18px]">
                <h2 className="text-[20px] leading-6 lg:text-[38px] xl:text-[47px] lg:leading-[56.99px] tracking-[-0.05em]">
                  {encord_features?.items[0]?.feature_heading?.text}
                </h2>
              </section>

              <div
                className="text-base lg:text-xl mt-[18px] lg:mt-[32px] events_page_feature_description"
                dangerouslySetInnerHTML={{
                  __html: encord_features?.items[0]?.feature_description?.html,
                }}
              />
              <p className="text-sm sm:text-base lg:text-xl leading-5 lg:leading-7 mt-[18px] lg:mt-8 font-bold">
                Learn more about
              </p>
              {encord_features?.items[0]?.tags?.text && (
                <section className="flex flex-wrap gap-2 mt-3">
                  {encord_features?.items[0]?.tags?.text
                    ?.split(",")
                    ?.map((x, index) => {
                      const [label, url] = x?.split("::");
                      return <Tag key={index} text={label} url={url} />;
                    })}
                </section>
              )}
            </section>
          </section>
        </article>
      )}
      {sub_features_list?.items?.length && (
        <article className="section_container mx-auto pt-[40px] md:pt-[91px]">
          <section className="flex bg-[#efefff] justify-center gap-2 md:gap-4 lg:gap-10 xl:gap-[68px] flex-wrap lg:flex-nowrap sm:my-14 my-6 sm:rounded-4xl rounded-[25px] shadow-lg">
            {sub_features_list?.items?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col sm:pt-14 pt-6 sm:pb-8 pb-4 max-w-[324px]"
                >
                  {item?.feature_video?.url ? (
                    <VideoComponent
                      loop
                      autoPlay
                      muted
                      playsinline
                      width={324}
                      className="rounded-[23.103px] h-[222px] max-w-[324px] object-none"
                    >
                      <source src={item?.feature_video?.url} type="video/mp4" />
                    </VideoComponent>
                  ) : (
                    <img
                      src={item?.feature_image?.url}
                      alt={"imgAlt"}
                      width={324}
                      className="rounded-[23.103px] h-[222px] max-w-[324px] object-none"
                      loading="lazy"
                      height={0}
                    />
                  )}
                  <h4 className="sm:text-[30px] text-[20px] text-blue-1100 font-normal sm:pt-[20px] pt-[15px]">
                    {item?.feature_heading?.text}
                  </h4>
                  <div
                    className="sm:text-[15.4px] text-[12px] font-normal sub_feature_description"
                    dangerouslySetInnerHTML={{
                      __html: item?.feature_description?.html,
                    }}
                  />
                </div>
              );
            })}
          </section>
        </article>
      )}
      {customizable_quality && (
        <article className="section_container mx-auto pt-[40px] lg:pt-[91px] space-y-14 lg:space-y-[161px] bg-white">
          <SplitSection
            video={customizable_quality?.feature_video_url?.url}
            image={{
              desktop: {
                url: customizable_quality?.feature_image?.url,
              },
              mobile: {
                url: customizable_quality?.feature_image_mobile?.url,
              },
            }}
            icon={<></>}
            title={customizable_quality?.feature_heading?.text}
            description={customizable_quality?.feature_description?.text}
            tags={
              customizable_quality?.tags?.text
                ? customizable_quality?.tags?.text?.split(",")
                : []
            }
            direction={"row-reverse"}
          />
        </article>
      )}

      <article className="relative">
        <section className="section_container py-16 sm:py-[80px] md:py-[91px] mx-auto">
          <h2 className="text-center text-[36px] sm:text-[40px] md:text-[60px] text-blue-1000 leading-11 sm:leading-12 md:leading-18 tracking-[-0.03em] md:tracking-[-0.065em] font-semibold  ">
            Our Customers
          </h2>
          <section className="mt-[85px] px-5">
            <Slider {...blogSliderSettings} className="blog-slider">
              {data.allPrismicBlog &&
                !!data.allPrismicBlog?.edges.length &&
                data.allPrismicBlog?.edges
                  .filter(
                    (_blog) =>
                      _blog.node.uid !== data?.prismicBlog?.uid &&
                      _blog.node.tags.includes("Customers")
                  )
                  .slice(0, 6)
                  .map((blog, index) => {
                    return (
                      <BlogCard
                        allowSubDetails={false}
                        searchText={""}
                        location={location}
                        key={`blog_card_${index}`}
                        blog={blog}
                      />
                    );
                  })}
            </Slider>
          </section>
        </section>
      </article>

      <article className="py-[86px] px-5 md:py-[150px] relative  overflow-hidden mt-[40px]">
        <section className="absolute inset-0 bg-purple-1000 -z-20" />
        <section className="absolute -bottom-10 md:bottom-0 -right-96 md:-right-60 lg:right-0 -z-10">
          <img
            src="https://images.prismic.io/encord/567d7939-a847-4c3e-99e4-e9f8057390c6_3D+Shape+Hero+Image+-+Glow+Effect+-+Dark+Theme+-+Dark+Theme+With+glass+6+-+Large+2+1.png?auto=compress,format"
            alt="3d modal"
            loading="lazy"
            className="min-w-[1290px] w-auto h-auto"
            width={0}
            height={0}
          />
        </section>
        <section className="max-w-[1000px] mx-auto text-center ">
          <h2 className="text-[36px] md:text-[44px] lg:text-[80px] text-white leading-11 md:leading-[53.25px] lg:leading-[96.82px] tracking-[-0.03em] font-semibold">
            {_data?.cta_heading?.text}
          </h2>
          <p className="mb-[50px] text-xl text-white leading-6 mt-9 tracking-[-0.025em]">
            {_data?.cta_description?.text}
          </p>
          <div className="flex flex-col items-center justify-center w-full space-y-3 sm:flex-row sm:space-y-0 sm:space-x-3">
            <LeadFormWithAPI pageName="Landing Page" location={location} />
          </div>
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query PrismicNestedLandingEventPages($uid: String!) {
    allPrismicBlog(sort: { first_publication_date: DESC }) {
      edges {
        node {
          uid
          data {
            author {
              document {
                ... on PrismicAuthor {
                  uid
                  data {
                    full_name {
                      text
                    }
                  }
                }
              }
            }
            content {
              html
            }
            body {
              ... on PrismicBlogDataBodyTable {
                id
                primary {
                  table_title {
                    text
                  }
                }
                items {
                  col1 {
                    text
                    html
                  }
                  col2 {
                    text
                    html
                  }
                  col3 {
                    text
                    html
                  }
                  col4 {
                    text
                    html
                  }
                }
              }
            }
            image {
              url
              gatsbyImageData(width: 400, placeholder: BLURRED)
            }
            sub_image {
              url
              gatsbyImageData(width: 400, placeholder: BLURRED)
            }
            title {
              text
            }
            read_time
          }
          first_publication_date
          tags
        }
      }
    }
    allPrismicNestedLandingEventPages(filter: { uid: { eq: $uid } }) {
      nodes {
        uid
        _previewable
        data {
          body {
            ... on PrismicNestedLandingEventPagesDataBodyEncordFeatures {
              id
              slice_type
              items {
                feature_description {
                  html
                  text
                }
                feature_heading {
                  html
                  text
                }
                feature_image {
                  alt
                  url
                }
                feature_image_mobile {
                  alt
                  url
                }
                feature_video_url {
                  url
                }
                heading_icon {
                  url
                  alt
                }
                tags {
                  html
                  text
                }
              }
            }
            ... on PrismicNestedLandingEventPagesDataBodySubFeaturesList {
              id
              slice_type
              items {
                feature_description {
                  html
                  text
                }
                feature_heading {
                  html
                  text
                }
                feature_image {
                  alt
                  url
                }
                feature_video {
                  url
                }
              }
            }
            ... on PrismicNestedLandingEventPagesDataBodyTrustedBrandsList {
              id
              slice_type
              items {
                brand_image {
                  alt
                  url
                }
              }
            }
          }
          cta_description {
            html
            text
          }
          cta_heading {
            html
            text
          }
          meta_description {
            html
            text
          }
          meta_heading {
            html
            text
          }
          hero_image {
            url
            alt
          }
          page_description {
            html
            text
          }
          page_heading {
            html
            text
          }
          page_sub_heading {
            html
            text
          }
          trusted_brands_list_label {
            html
            text
          }
        }
      }
    }
  }
`;
export const Head = ({ data }) => {
  const _data =
    data?.allPrismicNestedLandingEventPages &&
    !!data.allPrismicNestedLandingEventPages?.nodes.length &&
    data.allPrismicNestedLandingEventPages?.nodes[0]?.data;
  return (
    <SEO
      title={_data?.meta_title?.text}
      description={_data?.meta_description?.text}
    />
  );
};

export default EventsNestedLandingPage;
